import { ReactElement, useEffect, useRef, useState } from "react";
import Layout from "../components/layouts/welcomeLayout";
import Image from 'next/image'
import { GetSessionParams } from "next-auth/react";
import path from "path";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import Link from "next/link";


About.auth = false

About.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout hideFooter={true}>
      {page}
    </Layout>
  )
}
const pharmDeck = 'scriptify_clinical_pharmacist_waitlist'
const angelDeck = 'scriptify_angel_investment_deck'
export default function About() {
  const slideCount = 20;
  const images = [...Array(slideCount - 1 + 1)].map((_, i) => `${i+1}.svg`)
  return (
    <>
    {/* // <Carousel
    //   axis="horizontal" // Set axis to vertical
    //   showArrows={true} // Show navigation arrows
    //   emulateTouch={false} // Enable touch events for mobile devices
    //   infiniteLoop={true} // Enable infinite looping
    //   autoPlay={true} // Enable auto play
    //   showThumbs={false} // Hide thumbnail navigation
    //   className="myCarousel" // Set custom class name for styling
    // > */}
      {images.map((image, index) => (
        <div key={index}>
        
        <img
          key={index}
          className="w-full h-full ml-30 mr-30"
          src={pharmDeck + "/" + image}
          alt={`Image ${index}`}
        />
        {/* </Link> */}
        </div>
        
      ))}
      
    {/* // </Carousel> */}
    <div className="my-48 flex justify-center items-center flex-col">
      <h1 className="font-hk font-bold text-bold px-10 text-3xl text-center leading-tight tracking-tight text-gray-900">
        join us in transforming your role in healthcare
      </h1>
      <br/>
    <Link href="/waitlist" >
      <button className="font-hk bg-epione-green text-2xl center text-black hover:text-white font-bold py-2 px-4 rounded-lg">
        waitlist
      </button>
    </Link>
    </div>
    </>
  );
};

function getDeckPath() {
  if (process.env.NODE_ENV === 'production') {
    const pharmacistPitchDeck = 'public/scriptify_clinical_pharmacist_waitlist'
    const investmentPitchDeck = 'public/scriptify_angel_investment_deck'
    return {
      pharmacistPitchDeck,
      investmentPitchDeck
    }
  } else {
    console.log(process.cwd(), 'CURRENT PATH')
    const pharmacistPitchDeck = './public/scriptify_clinical_pharmacist_waitlist'
    const investmentPitchDeck = './public/scriptify_angel_investment_deck'
    return { pharmacistPitchDeck, investmentPitchDeck }
  }
}


// const fs = require('fs')
// // Get ServerSideProps
// export async function getServerSideProps(context: GetSessionParams) {
//   // public/Scriptify Angel Investment Deck

//   console.log(process.cwd(), 'CURRENT PATH')
//   console.log(process.env.NODE_ENV, 'NODE ENV')
//   const { pharmacistPitchDeck } = getDeckPath()
//   console.log("dirs", await fs.promises.readdir(pharmacistPitchDeck))
//   const files = await fs.promises.readdir(pharmacistPitchDeck);
//   files.sort((a: string, b: string) => {
//     const numA = Number(a.match(/\d+/));
//     const numB = Number(b.match(/\d+/));
//     return numA - numB;
//   })
//   console.log(files)
//   return {
//     props: { images: files }, // will be passed to the page component as props
//   }
// }